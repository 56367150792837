import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { TranslateModule } from '@ngx-translate/core';
import { TreatmentsListComponent } from './treatments-list/treatments-list.component';
import { TreatmentDetailsComponent } from './treatment-details/treatment-details.component';
import { SelectorTeethComponent } from './selector-teeth/selector-teeth.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ServicesComponent } from './services/services.component';

@NgModule({
  declarations: [PagetitleComponent,TreatmentsListComponent, TreatmentDetailsComponent, SelectorTeethComponent, ServicesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    TranslateModule,
    NgSelectModule
  ],
  exports: [PagetitleComponent,TreatmentsListComponent,TreatmentDetailsComponent,SelectorTeethComponent,ServicesComponent]
})
export class UIModule { }
