import { Component, Input, OnInit } from '@angular/core';
import { Diagnostic } from 'src/app/core/models/diagonstic';
import { Service } from 'src/app/core/models/service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Input() services: Service[];
  @Input() diagnostic: Diagnostic;
  @Input() type;
  @Input() selectedService;
  @Input()totalPriceFirst
  @Input()totalPriceFirstBeforeDiscount
  @Input()totalPriceSecondBeforeDiscount
  @Input()totalPriceSecond
  initialPriceFirst: number;
  newPriceFirst: number;
  totPriceFirst: number;
  initialPriceSecond: number;
  newPriceSecond: number;
  constructor() {
    }

  ngOnInit(): void {
    if (!this.initialPriceFirst) {
      this.initialPriceFirst = this.totalPriceFirst;
    }
    this.totPriceFirst = this.totalPriceFirstBeforeDiscount;  
    this.newPriceFirst = this.initialPriceFirst;
    if (!this.initialPriceSecond) {
      this.initialPriceSecond = this.totalPriceSecond;
    }
  
    this.newPriceSecond = this.initialPriceSecond;
  }

  getCostAfterDiscount() {
    setTimeout(() => {
    const nombreFloat = this.diagnostic.total_after_discount_with_hotel_first_visit - (this.diagnostic.total_after_discount_with_hotel_first_visit * this.diagnostic.extra_discount_percent_first_visit / 100);
    this.diagnostic.extra_discount_price_first_visit = Math.round(nombreFloat * 100) / 100
  }, 1000); // Delay of 1 second (adjust as needed)
  }
  getPourcentageDiscount() {
    this.diagnostic.extra_discount_percent_first_visit = parseInt((((this.diagnostic.total_after_discount_with_hotel_first_visit -   this.diagnostic.extra_discount_price_first_visit) / this.diagnostic.total_after_discount_with_hotel_first_visit) * 100).toFixed(2));
  }

  getCostAfterDiscountSecond() {
    setTimeout(() => {
      const nbreFloats = this.diagnostic.total_after_discount_with_hotel_second_visit - (this.diagnostic.total_after_discount_with_hotel_second_visit * (this.diagnostic.extra_discount_percent_second_visit / 100));
      this.diagnostic.extra_discount_price_second_visit = Math.round(nbreFloats * 100) / 100
    }, 1000); // Delay of 1 second (adjust as needed)
  }
  getPourcentageDiscountSecond() {
    this.diagnostic.extra_discount_percent_second_visit = parseInt((((this.diagnostic.total_after_discount_with_hotel_second_visit -   this.diagnostic.extra_discount_price_second_visit) / this.diagnostic.total_after_discount_with_hotel_second_visit) * 100).toFixed(2));
  }

  changeTotal() {
  
    if (!this.initialPriceFirst) {
      this.initialPriceFirst = this.totalPriceFirst;
    }
  
    this.newPriceFirst = this.initialPriceFirst;
  
    if (this.diagnostic.hotel_accommodation_num_nights_first_visit > 0 && this.diagnostic.hotel_accommodation_price_first_visit >= 0) {
      this.newPriceFirst += this.diagnostic.hotel_accommodation_num_nights_first_visit * this.diagnostic.hotel_accommodation_price_first_visit;
      this.diagnostic.total_after_discount_with_hotel_first_visit = this.diagnostic.totalAfterdiscount + (this.diagnostic.hotel_accommodation_num_nights_first_visit * this.diagnostic.hotel_accommodation_price_first_visit);
      this.diagnostic.totaldiscount = this.newPriceFirst;
    }
    
  }

  
  changeTotalSecond() {
    if (!this.initialPriceSecond) {
      this.initialPriceSecond = this.totalPriceSecond;
    }
  
    this.newPriceSecond = this.initialPriceSecond;
  
    if (this.diagnostic.hotel_accommodation_num_nights_second_visit > 0 && this.diagnostic.hotel_accommodation_price_second_visit >= 0) {
      this.newPriceSecond += this.diagnostic.hotel_accommodation_num_nights_second_visit * this.diagnostic.hotel_accommodation_price_second_visit;
      this.diagnostic.total_after_discount_with_hotel_second_visit = this.diagnostic.totalAfterdiscountSecond + (this.diagnostic.hotel_accommodation_num_nights_second_visit * this.diagnostic.hotel_accommodation_price_second_visit);
      this.diagnostic.totaldiscountSecond = this.newPriceSecond;
    }
  }
  
  removeDuplicates<T>(arr: T[]): T[] {
    return [...new Set(arr)];
  }

  addServiceSelected(item) {

    if (this.selectedService.includes(item)) {
      const uniqueArray = this.removeDuplicates(this.selectedService);
      const index: number = this.selectedService.indexOf(item);
      this.selectedService.splice(index, 1);
    }
    else
      this.selectedService.push(item);
  }

}
