<div class="row justify-content-center">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title"></h4>
      <p class="card-title-desc">Anesthesia Type</p>

      <div *ngIf="type =='First Visit'">
        <div class="form-check form-check-inline font-size-16">
          <input class="form-check-input" type="radio" name="FirstpaymentoptionsRadio1" id="FirstpaymentoptionsRadio1" 
            value="1"  [(ngModel)]="diagnostic.anesthesia_type_first_visit">
          <label class="form-check-label font-size-13" for="paymentoptionsRadio1">
            General </label>
        </div>
        <div class="form-check form-check-inline font-size-16">
          <input class="form-check-input" type="radio" name="FirstpaymentoptionsRadio2" id="FirstpaymentoptionsRadio2" value="0"
          [(ngModel)]="diagnostic.anesthesia_type_first_visit" >
          <label class="form-check-label font-size-13" for="paymentoptionsRadio2" >
            Local</label>
        </div>
      </div>
      <div *ngIf="type =='Second Visit'">
        <div class="form-check form-check-inline font-size-16">
          <input class="form-check-input" type="radio" name="SecondpaymentoptionsRadio12" id="SecondpaymentoptionsRadio12" 
            value="1" [(ngModel)]="diagnostic.anesthesia_type_second_visit">
          <label class="form-check-label font-size-13" for="paymentoptionsRadio12">
            General </label>
        </div>
        <div class="form-check form-check-inline font-size-16">
          <input class="form-check-input" type="radio" name="SecondpaymentoptionsRadio21" id="SecondpaymentoptionsRadio21" value="0"
            [(ngModel)]="diagnostic.anesthesia_type_second_visit" checked>
          <label class="form-check-label font-size-13" for="paymentoptionsRadio21">
            Local</label>
        </div>
      </div>

      <h5 class="mt-5 mb-3 font-size-15">Treatment Offer & Services</h5>
      <div class="p-4 border">
        <div class="table-responsive">
          <table class="table table-nowrap align-middle mb-0">
            <tbody>
              <tr style="background-color: #fff;" *ngFor="let item of services; let i = index;">
                <td style="width: 40px;">
                  <div class="form-check font-size-16">
                    <input type="checkbox" class="form-check-input" id="customCheck" value="{{item.service_id}}" (click)="addServiceSelected(item)">
                    <label class="form-check-label" for="customCheck"></label>
                  </div>
                </td>
                <td>
                  <h5 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);"
                      class="text-dark">{{item.name}}</a>
                  </h5>
                  <!-- <div class="row">
                  <div class="col-lg-3 text-center">
                    <label class="visually-hidden" for="autoSizingInputGroup1"></label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="autoSizingInputGroup1"
                            placeholder="">
                    </div>
                </div>
                <div class="col-lg-3 text-center">
                    <label class="visually-hidden" for="autoSizingInputGroup2">Username </label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="autoSizingInputGroup2"
                            placeholder="">
                    </div>
                </div>
              </div> -->
                </td>
              </tr>
              <tr *ngIf="type =='First Visit'" style="background-color: #fff;">
                <td style="width: 40px;">
                 
                </td>
                <td>
                  <h5 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);" class="text-dark">Hotel
                      accommodation</a></h5>
                  <div class="row mt-3">
                    
                    <div class="col-lg-2 text-center">
                      <label>Hotel Nights</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1"
                          placeholder=" Hotel Nights" class="form-control" [(ngModel)]="diagnostic.hotel_accommodation_num_nights_first_visit"  (ngModelChange)="changeTotal()"></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>Price</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup2" placeholder="Price"
                          class="form-control"  [(ngModel)]="diagnostic.hotel_accommodation_price_first_visit"   (ngModelChange)="changeTotal()"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="background-color: #fff;" *ngIf="type =='Second Visit'">
                <td style="width: 40px;">
                  
                </td>
                <td>
                  <h5 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);" class="text-dark">Hotel
                      accommodation</a></h5>
                  <div class="row mt-3">
                    <div class="col-lg-2 text-center">
                      <label>Hotel Nights</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1"
                          placeholder=" Hotel Nights" class="form-control" [(ngModel)]="diagnostic.hotel_accommodation_num_nights_second_visit" (ngModelChange)="changeTotalSecond()"></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>Price</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup2" placeholder="Price"
                          class="form-control"  [(ngModel)]="diagnostic.hotel_accommodation_price_second_visit" (ngModelChange)="changeTotalSecond()"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="background-color: #fff;" *ngIf="type =='First Visit'">
                <td style="width: 40px;">
                  
                </td>
                <td>
                  <h5 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);" class="text-dark">Extra
                      discount</a></h5>
                  <div class="row">
                    <div class="col-lg-2 text-center">
                      <label>Total Price</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1" 
                          placeholder="Price " class="form-control"  [(ngModel)]="diagnostic.totaldiscount" disabled></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>Price Discount</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1" 
                          placeholder="Price " class="form-control"  [(ngModel)]="diagnostic.total_after_discount_with_hotel_first_visit" disabled></div>
                    </div>
                    
                    <div class="col-lg-2 text-center">
                      <label>Price Extra Discount</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1"
                          placeholder="Price Discount" class="form-control"  [(ngModel)]="diagnostic.extra_discount_price_first_visit" (ngModelChange)="getPourcentageDiscount()"></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>% Extra Discount</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup2" placeholder="% Discount" (ngModelChange)="getCostAfterDiscount()"
                          class="form-control"  [(ngModel)]="diagnostic.extra_discount_percent_first_visit"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="background-color: #fff;" *ngIf="type =='Second Visit'">
                <td style="width: 40px;">
                  
                </td>
                <td>
                  <h5 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);" class="text-dark">Extra
                      discount</a></h5>
                  <div class="row">
                    <div class="col-lg-2 text-center">
                      <label>Total Price</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1"
                          placeholder="Price " class="form-control"  [(ngModel)]="diagnostic.totaldiscountSecond" disabled></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>Price Discount</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1"
                          placeholder="Price " class="form-control"  [(ngModel)]="diagnostic.total_after_discount_with_hotel_second_visit" disabled></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>Price Extra Discount</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup1"
                          placeholder="Price Discount" class="form-control"  [(ngModel)]="diagnostic.extra_discount_price_second_visit" (ngModelChange)="getPourcentageDiscountSecond()"></div>
                    </div>
                    <div class="col-lg-2 text-center">
                      <label>% Extra Discount</label>
                      <div class="input-group"><input type="number" id="autoSizingInputGroup2" placeholder="% Discount"
                          class="form-control"  [(ngModel)]="diagnostic.extra_discount_percent_second_visit" (ngModelChange)="getCostAfterDiscountSecond()"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="type =='First Visit'"><label for="commentmessage-input">Additional Notes</label><textarea id="commentmessage-input"
        placeholder="Additional Notes..." rows="3" class="form-control" [(ngModel)]="diagnostic.additional_first_visit"></textarea></div>
  </div>
  <div class="mb-3"  *ngIf="type =='Second Visit'"><label for="commentmessage-input">Additional Notes</label><textarea id="commentmessage-input"
    placeholder="Additional Notes..." rows="3" class="form-control" [(ngModel)]="diagnostic.additional_second_visit"></textarea></div>
</div>