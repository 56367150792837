import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
import { Credentials } from '../models/credentials';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private http: HttpClient,
      private storageService: StorageService,
      private router: Router
      ) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */

     login(credentials: Credentials) {
      const url = environment.baseUrl + "/auth/login";
      return this.http.post<any>(url, credentials);
    }

    isLoggedIn() {
      return this.getUserToken() != null;
    }

    getUserToken() {
      return this.storageService.read(StorageService.USER_TOKEN_KEY);
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
     logout() {
      this.router.navigateByUrl("/account/login");
      this.storageService.removeAll();
    }
}

