import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Treatment } from 'src/app/core/models/treatment';

@Component({
  selector: 'app-treatments-list',
  templateUrl: './treatments-list.component.html',
  styleUrls: ['./treatments-list.component.scss']
})
export class TreatmentsListComponent implements OnInit {
  @Input() title: string;
  @Input() treatments: Treatment[];
  selectedItems: Treatment[] = [];
   @Input() selectedTreatmentSingleVisit = [];
  @Input() selectedTreatmentDoubleVisit = [];


  constructor() { }

  ngOnInit(): void {
  }

  selectItem(item) {
    this.isChecked(item);
  
    if (this.title === "Single Visit") {
      if (this.selectedTreatmentSingleVisit.includes(item)) {
        const index = this.selectedTreatmentSingleVisit.indexOf(item);
        this.selectedTreatmentSingleVisit.splice(index, 1);
      } else {
        this.selectedTreatmentSingleVisit.push(item);
      }
    } else {
      if (this.selectedTreatmentDoubleVisit.includes(item)) {
        const index = this.selectedTreatmentDoubleVisit.indexOf(item);
        this.selectedTreatmentDoubleVisit.splice(index, 1);
      } else {
        this.selectedTreatmentDoubleVisit.push(item);
      }
    }
  }
  
  isChecked(item): boolean {
    if (this.title == "Single Visit")
      return this.selectedTreatmentSingleVisit.length > 0 && this.selectedTreatmentSingleVisit.includes(item);
    else
      return this.selectedTreatmentDoubleVisit.length > 0 && this.selectedTreatmentDoubleVisit.includes(item);
  }

}
