<div class="col-lg-12">
    <div class="card" >
        <h5 class="card-header bg-transparent border-bottom text-uppercase" style="display: flex; align-items: center;">
            <span>{{ treatment.name }}</span>
            <img alt="" *ngIf="treatment.singlePay != 2" class="rounded-circle avatar-sm" [ngStyle]="{ background: treatment.color }"
                style="margin-left: 10px;">
            <div class="flex-grow-1"></div>
            <i class="bx bx-trash" (click)="getTreatmentsSeleceted()"></i>
            <div class="font-size-32" (click)="showHideCard()"> {{(showCard ? '-' : '+')}}</div>
        </h5>
        <br>
        <div  id="{{type}}{{treatment.name}}">
        <div style="display: flex; justify-content: flex-end;" *ngIf="treatment.singlePay != 2">
            <ng-select style="width:350px" [items]="treatment.types" placeholder="Types" bindLabel="name"
                [multiple]="false" bindValue="type_id" [(ngModel)]="treatmentType" (change)="getType()"></ng-select>
        </div>
        <div class="card-body text-center">
                <app-selector-teeth *ngIf="treatment.singlePay == 0 && type == 'First Visit'" [teethsSelected]="teethSelected"
                [treatment]="treatment" [type]="type"></app-selector-teeth>
                <app-selector-teeth *ngIf="treatment.singlePay == 0 &&  type == 'Second Visit'" [teethsSelectedSecond]="teethSelectedSecond"
                [treatment]="treatment" [type]="type"></app-selector-teeth>
                
                <div class="row left_right" *ngIf="treatment.singlePay == 2 && type == 'First Visit'">
                    <div class="col-lg-6">
                        <div class="mt-4 mb-0">
                            <label>left</label>
                            <input type="checkbox" name="left_first_visit" id="1" [(ngModel)]="diagnostic.left_right_first_visit[0]">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mt-4 mb-0">
                            <label >right</label>
                            <input type="checkbox" name="right_first_visit" id="2" [(ngModel)]="diagnostic.left_right_first_visit[1]">
                        </div>
                    </div>
                </div>

                <div class="row left_right" *ngIf="treatment.singlePay == 2 && type == 'Second Visit'">
                    <div class="col-lg-6">
                        <div class="mt-4 mb-0">
                            <label>left</label>
                            <input type="checkbox" name="left_second_visit" id="3" [(ngModel)]="diagnostic.left_right_second_visit[0]">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mt-4 mb-0">
                            <label >right</label>
                            <input type="checkbox" name="right_second_visit" id="4" [(ngModel)]="diagnostic.left_right_second_visit[1]">
                        </div>
                    </div>
                </div>    
            <div class="row ">
                <h5 *ngIf="treatment.singlePay != 2"> Costing & Payment</h5>
                <div class="col-lg-3">
                    <div class="mt-4 mb-0" *ngIf="treatment.singlePay != 1 && treatment.singlePay != 2">
                        <label for="cardnameInput">Cost per tooth</label>
                        <input type="number" class="form-control" id="cardnameInput" [(ngModel)]="treatment.cost"
                            placeholder="Cost per tooth">
                    </div>
                    <div class="mt-4 mb-0" *ngIf="treatment.singlePay == 1">
                        <label for="cardnameInput"> Total Cost</label>
                        <input type="number" class="form-control" id="cardnameInput" [(ngModel)]="treatment.cost"
                           placeholder="Total Cost">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="mt-4 mb-0" *ngIf="treatment.singlePay == 1">
                        <label for="cardnameInput"> Total Cost after discount</label>
                        <input type="number" class="form-control" id="cardnameInput" [(ngModel)]="costDiscount" 
                            placeholder=" Total Cost after discount"  (ngModelChange)="getPourcentageDiscount()" >
                    </div>
                    <div class="mt-4 mb-0"  *ngIf="treatment.singlePay != 1 && treatment.singlePay != 2">
                        <label for="expirydateInput">Cost per tooth after discount</label>
                        <input type="number" class="form-control" id="expirydateInput"
                            placeholder="Cost per tooth after discount" [(ngModel)]="costDiscount"
                            (ngModelChange)="getPourcentageDiscount()">
                    </div>
                </div>
                <div class="col-lg-3" *ngIf="treatment.singlePay != 2">
                    <div class="mt-4 mb-0">
                        <label for="cvvcodeInput">Discount %</label>
                        <input type="number" class="form-control" id="cvvcodeInput" [(ngModel)]="pourcentageDisconut"
                            placeholder="Discount %" (ngModelChange)="getCostAfterDiscount()">
                    </div>
                </div>
                
            </div>
        </div>
</div>
    </div>
</div>