import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Utils } from '../utils';
import { reduce } from 'rxjs/operators';
import { TreatmentService } from 'src/app/core/services/treatment.service';

@Component({
  selector: 'app-selector-teeth',
  templateUrl: './selector-teeth.component.html',
  styleUrls: ['./selector-teeth.component.scss']
})
export class SelectorTeethComponent implements OnInit {
  teeths: any[];
  @Input() treatment;
  @Input() teethsSelected: any[] = [];
  selectedTeeth: any;
  @Input() type
  @Input() teethsSelectedSecond: any[] = [];

  constructor(private treatmentService: TreatmentService) { }

  ngOnInit(): void {
    this.getTeeth()
    if (this.type == "First Visit") {
      this.treatmentService.items$.subscribe(items => {
        items.forEach((teeth) => {
          const matchingTeeth = this.teeths.find(element => element.point === teeth.point);
          if (matchingTeeth) {
            if (this.treatment.type == 1) {
              matchingTeeth.color = teeth.color;
              if (teeth.color == "#E1E1E1") {
                this.removeItem(teeth)
              }
            }

            if (teeth.color != "#E1E1E1") {
              this.teethsSelected.push(matchingTeeth);
            }
          }
        });

      })
    }
    else {
      this.treatmentService.itemsSecond$.subscribe(items => {
        items.forEach((teeth) => {
          const matchingTeeth = this.teeths.find(element => element.point === teeth.point);
          if (matchingTeeth) {
            if (this.treatment.type == 1) {
              matchingTeeth.color = teeth.color;
              if (teeth.color == "#E1E1E1") {
                this.removeItem(teeth)
              }
            }

            if (teeth.color != "#E1E1E1") {
              this.teethsSelectedSecond.push(matchingTeeth);
            }
          }
        });
      })
    }
  }

  getTeeth() {
    this.teeths = Utils.getSelectorTeethPoints();
  }
  
  selectTeeth(item) {
    if (this.type == 'First Visit') {
      const index = this.teethsSelected.indexOf(item);
      const allTeeth = this.teeths.filter((element) => element.key === item.key);
      if (index > -1 && item.color != "#E1E1E1") {
        if (this.treatment.type == 1) {
        this.teethsSelected = [];
        }
        allTeeth.forEach((teeth) => {
          const index1 = this.teethsSelected.indexOf(teeth);
          this.teethsSelected.splice(index1, 1);
          teeth.color = '#E1E1E1';
          // this code will be implemented yet
          if (this.treatment.type == 1) {
            this.changeItem(item)
          }
        });
      } else if (item.color == "#E1E1E1") {
        allTeeth.forEach((teeth) => {
          teeth.color = this.treatment.color;
          // this code will be implemented yet
          if (this.treatment.type == 1) {
            this.addItem(teeth)
          }
          this.teethsSelected.push(teeth);
        });
      }
    } else {
      const index = this.teethsSelectedSecond.indexOf(item);
      const allTeeth = this.teeths.filter((element) => element.key === item.key);
      if (index > -1 && item.color != "#E1E1E1") {
        if (this.treatment.type == 1) {
          this.teethsSelectedSecond = [];
          }        allTeeth.forEach((teeth) => {
          const index1 = this.teethsSelectedSecond.indexOf(teeth);
          this.teethsSelectedSecond.splice(index1, 1);
          teeth.color = '#E1E1E1';
          // this code will be implemented yet
          if (this.treatment.type == 1) {
            this.changeItem(item)
          }
        });
      } else if (item.color == "#E1E1E1") {
        allTeeth.forEach((teeth) => {
          teeth.color = this.treatment.color;
          // this code will be implemented yet
          if (this.treatment.type == 1) {
            this.addItem(teeth)
          }
          this.teethsSelectedSecond.push(teeth);
        });
      }
    }
  }


  getKeyTeethSelected(array) {
    const uniqueArray = [];
    const uniqueKeys = {};
    for (const item of array) {
      if (item) {
        const key = item.key;
        if (!uniqueKeys[key] && item.color != '#E1E1E1') {
          uniqueKeys[key] = true;
          uniqueArray.push(item);
        }
      }
    }
    return uniqueArray;
  }

  addItem(item: any) {
    this.treatmentService.addItem(item, this.type);
  }

  removeItem(item: any) {
    this.treatmentService.removeItem(item, this.type);
  }

  changeItem(item: any) {
    this.treatmentService.changeItem(item, this.type);
  }
}


