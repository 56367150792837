export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  baseUrl: "https://win.api.clinicapp.vayetek.com/api",
  filesUrl: 'https://clinicapp-dev.s3.eu-west-3.amazonaws.com/',
};
