import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Treatment } from 'src/app/core/models/treatment';

@Component({
  selector: 'app-treatment-details',
  templateUrl: './treatment-details.component.html',
  styleUrls: ['./treatment-details.component.scss']
})
export class TreatmentDetailsComponent implements OnInit {
  @Input() treatment: Treatment;
  @Output() sendTreatmentToDelete = new EventEmitter<any>();
  @Input() teethSelected: any[] = [];
  @Input() teethSelectedSecond: any[] = [];
  @Input() type;
  @Input() diagnostic;
  @Input() treatmentTypes = [];
  @Input() costs = [];
  treatmentType;
  costDiscount: number;
  showCard: boolean = true;
  pourcentageDisconut: number;
  constructor() { }

  ngOnInit(): void {
  }

  getTreatmentsSeleceted() {
    this.sendTreatmentToDelete.emit(this.treatment)
  }

  getSelecetedTeeth(e) {
    if (this.type == "First Visit")
      this.teethSelected = e
    else
      this.teethSelectedSecond = e
  }

  getCostAfterDiscount() {
    this.costDiscount = this.treatment.cost - (this.treatment.cost * (this.pourcentageDisconut / 100))
    this.getCosts(this.costDiscount, this.pourcentageDisconut)
  }
  getPourcentageDiscount() {
    this.pourcentageDisconut = parseInt((((this.treatment.cost - this.costDiscount) / this.treatment.cost) * 100).toFixed(2));
    this.getCosts(this.costDiscount, this.pourcentageDisconut)
  }

  showHideCard() {
    var element = document.getElementById(this.type + this.treatment.name);
    element.classList.toggle("display");
    this.showCard = !this.showCard
  }

  getType() {
    let isFirst = this.type == "First Visit" ? 1 : 2;
    let treatmentIndex = this.treatmentTypes.findIndex(treatment => treatment.treatment_id === this.treatment.treatment_id && treatment.isFirst === isFirst);

    if (treatmentIndex === -1) {
      // Treatment does not exist, add a new entry
      this.treatmentTypes.push({
        "type": this.treatmentType,
        "treatment_id": this.treatment.treatment_id,
        "isFirst": isFirst
      });
    } else {
      // Treatment exists
      // Update the type only if isFirst is different
      this.treatmentTypes[treatmentIndex].type = this.treatmentType;
    }
  }

  getCosts(costDiscount, pourcentageDisconut) {
    let isFirst = this.type == "First Visit" ? 1 : 2;
    let treatmentIndex = this.costs.findIndex(treatment => treatment.treatment_id === this.treatment.treatment_id && treatment.isFirst === isFirst);

    if (treatmentIndex === -1) {
      // Treatment does not exist, add a new entry
      this.costs.push({
        "costDiscount": costDiscount,
        "pourcentageDisconut": pourcentageDisconut,
        "treatment_id": this.treatment.treatment_id,
        "isFirst": isFirst
      });
    } else {
      // Treatment exists
      // Update the type only if isFirst is different
      this.costs[treatmentIndex].costDiscount = costDiscount;
      this.costs[treatmentIndex].pourcentageDisconut = pourcentageDisconut;
    }
  }
}


