
import { Role } from "./role";

export class User {
  user_id: number;
  last_name: string;
  first_name: string;
  password: string;
  email: string;
  email_confirmed: string;
  verification_code: string;
  mobile: string;
  role_id: number;
  clinique_id: number;
  role: Role = new Role();
}
