<div class="card">
    <h5 class="card-header bg-transparent border-bottom text-uppercase" style="display: flex; align-items: center;">
        <span style="text-align: center;">Selector teeth</span>
    </h5>
    <div class="card-body">
        <br>
        <div></div><svg class="md:w-full teeth-selector-vectors" width="701" height="240" viewBox="0 0 701 240"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_28_842)" *ngFor="let item of teeths" (click)="selectTeeth(item)">
                <path [attr.d]="item.point" class="cursor-pointer" [attr.fill]="item.color"></path>
            </g>
            <defs>
                <clipPath id="clip0_28_842">
                    <rect width="701" height="240" fill="inherit"></rect>
                </clipPath>
            </defs>
        </svg>
        <div class="row" style="text-align: left;">
            <span class="col-md-6" *ngIf="type == 'First Visit'">Selected teeth:
                <ng-container *ngFor="let item of getKeyTeethSelected(teethsSelected); let last = last">
                    {{ item?.key }}
                    <ng-container *ngIf="!last">-</ng-container>
                </ng-container>
            </span>
            <span class="col-md-6" *ngIf="type == 'Second Visit'">Selected teeth:
                <ng-container *ngFor="let item of getKeyTeethSelected(teethsSelectedSecond); let last = last">
                    {{ item?.key }}
                    <ng-container *ngIf="!last">-</ng-container>
                </ng-container>
            </span>
        </div>
        
    </div>
</div>