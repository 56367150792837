import { OwlOptions } from "ngx-owl-carousel-o";
import { combineLatest } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import Swal, { SweetAlertIcon } from "sweetalert2";

export class Utils {
  public static getFristLettres(user) {
    return (
      user.first_name.charAt(0).toUpperCase() +
      user.last_name.charAt(0).toUpperCase()
    );
  }


  static swalMessage(
    key: string,
    message: string,
    icon: SweetAlertIcon,
    transalteService: TranslateService
  ) {
    combineLatest(
      transalteService.get(key),
      transalteService.get(message)
    ).subscribe((res) => {
      Swal.fire(res[0], res[1], icon);
    });
  }

  static isEmail(email: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  static swalMessageWithCallback(
    key: string,
    message: string,
    type: string,
    params: any,
    transalteService: TranslateService,
    callback
  ) {
    combineLatest(
      transalteService.get(key),
      transalteService.get(message)
    ).subscribe((res) => {
      let object = Object.assign(
        {
          title: res[0],
          text: res[1],
          icon: type,
        },
        params
      );
      Swal.fire(object).then(function (isConfirm) {
        callback(isConfirm);
      });
    });
  }

  static isMobile(mobile) {
    return /^[0-9]+$/.test(mobile);
  }

  static isLink(link: string) {
    return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&amp;//=]*)+$/.test(
      link
    );
  }

  static isString(name: string) {
    return /^[a-zA-Z]|[a-zA-Z] [a-zA-Z]+$/.test(name);
  }

  static getSelectorTeethPoints() {
    return [{ "point": "M35.3264 89.1431C35.4773 82.2548 34.0766 80.9515 27.9354 82.2494C22.7272 83.3196 17.3544 83.3068 12.1514 82.2117C6.88286 81.1615 4.17318 83.1865 3.6237 88.416C3.42977 90.2956 2.95032 92.1859 3.00419 94.0386C3.13348 98.6056 5.35295 101.32 10.1959 100.453C14.5055 99.6881 18.3465 100.518 22.3544 102.02C27.5907 103.98 31.6309 101.955 33.8127 96.8068C34.827 94.3798 35.3418 91.7733 35.3264 89.1431Z", "color": "#E1E1E1", "key": 18 },
    { "point": "M5.83817 27.9139C5.50418 29.201 5.12708 29.9012 5.18095 30.5636C6.4092 45.7403 6.86171 60.9116 5.28331 76.0883C5.04089 78.388 6.41459 78.8458 8.35392 79.2282C15.4798 80.556 22.794 80.5084 29.9021 79.0881C33.1343 78.485 33.9585 77.1008 33.4899 74.0257C31.8737 63.459 29.5034 53.1294 24.9298 43.3976C22.3566 37.8769 19.0555 32.7255 15.1147 28.0808C14.2797 27.1006 13.3531 25.5603 11.9363 26.1797C10.0616 26.9983 11.1282 28.7755 11.3437 30.1974C11.7316 32.7663 13.7302 35.136 12.6959 38.4643C9.37207 35.3999 9.70607 30.876 5.83817 27.9139Z", "color": "#E1E1E1", "key": 18 },
    { "point": "M57.4231 47.9104C61.0378 40.7098 59.1793 26.8257 55.0475 22.8511C54.2448 22.0809 53.4313 20.9823 52.1492 21.6662C50.9749 22.291 50.9371 23.6266 51.2173 24.7037C53.1081 31.9797 55.0906 39.2342 57.4231 47.9104Z", "color": "#E1E1E1", "key": 17 },
    { "point": "M61.4365 23.939C59.9981 26.8041 60.9463 29.0068 61.1671 31.1719C61.652 35.9112 62.0237 40.6506 61.485 45.4007C61.2695 47.3234 60.6123 49.413 58.4467 49.7792C56.0764 50.1778 55.1067 48.0558 54.5896 46.2893C53.3074 41.9162 52.4347 37.4246 51.1742 33.0515C50.6355 31.2096 50.6355 28.2044 47.9096 28.5006C45.7979 28.7268 45.841 31.4196 45.5716 33.2346C43.6808 46.0631 44.3326 59.0317 43.3899 71.9142C43.1259 75.5549 45.5447 76.0127 48.2382 75.7326C53.5229 75.1671 58.8561 74.7093 64.033 73.5784C67.5885 72.8082 72.1136 76.9174 74.3923 72.6305C76.1054 69.3991 74.1283 65.1714 73.5304 61.4391C71.4402 48.519 68.2026 35.9382 61.4365 23.939Z", "color": "#E1E1E1", "key": 17 },
    { "point": "M40.0015 90.5434C39.9099 100.356 44.1064 103.604 53.1836 100.162C56.9545 98.7241 60.1867 99.742 63.7098 100.496C71.2247 102.112 75.7714 98.4279 76.8273 90.1717C78.0501 80.6769 74.9472 76.4276 66.6242 76.1098C61.2372 75.9052 56.0979 77.6501 50.8133 78.2641C41.6822 79.2928 40.093 81.0754 40.0015 90.5434Z", "color": "#E1E1E1", "key": 17 },
    // { "point": "M35.3264 89.1431C35.4773 82.2548 34.0766 80.9515 27.9354 82.2494C22.7272 83.3196 17.3544 83.3068 12.1514 82.2117C6.88286 81.1615 4.17318 83.1865 3.6237 88.416C3.42977 90.2956 2.95032 92.1859 3.00419 94.0386C3.13348 98.6056 5.35295 101.32 10.1959 100.453C14.5055 99.6881 18.3465 100.518 22.3544 102.02C27.5907 103.98 31.6309 101.955 33.8127 96.8068C34.827 94.3798 35.3418 91.7733 35.3264 89.1431Z", "color": "#E1E1E1" },
    { "point": "M100.823 77.1224C98.4527 77.1224 96.5457 77.2248 94.6549 77.1224C89.2678 76.7724 85.9763 79.4167 84.4841 84.3607C82.9919 89.3048 81.532 95.0889 85.944 98.7619C89.5479 101.756 95.0212 101.412 99.3147 99.1605C102.967 97.2486 105.951 97.324 109.485 99.1174C115.244 102.036 118.945 101.067 121.649 96.4246C124.968 90.6835 124.413 80.8762 120.572 78.3342C119.834 77.8495 118.708 77.3863 117.938 77.5856C112.007 79.0828 106.215 76.4062 100.823 77.1224Z", "color": "#E1E1E1", "key": 16 },
    { "point": "M120.739 57.9386H120.443C120.443 53.9479 120.717 49.9356 120.383 45.9717C119.78 38.8035 119.446 31.4682 114.705 25.4309C113.563 23.9714 112.308 21.9949 110.159 22.5927C107.805 23.2551 108.268 25.6948 108.306 27.4721C108.413 33.197 108.106 38.8789 106.765 44.4422C106.108 47.1889 105.251 50.4849 101.992 50.905C98.5874 51.3574 98.1833 47.7651 96.9389 45.5786C94.1915 40.7315 93.3404 35.1897 91.3633 30.0572C90.9647 29.0339 90.7115 27.596 89.4294 27.4398C87.7217 27.2297 87.3877 28.8723 86.9029 30.0303C86.4147 31.2657 86.0362 32.5416 85.7716 33.8433C83.0188 46.3433 85.373 58.8111 86.111 71.2842C86.2349 73.3577 87.2584 74.9626 89.7472 74.7149C97.7308 73.9339 105.644 74.5156 113.59 75.4419C118.633 76.029 121.477 73.8909 120.809 68.1821C120.421 64.8161 120.739 61.3585 120.739 57.9386Z", "color": "#E1E1E1", "key": 16 },
    { "point": "M105.93 35.4321C105.93 30.1542 103.64 25.7972 100.984 21.6072C99.9931 20.0399 98.781 18.1011 96.54 18.7797C94.4445 19.4206 94.1213 21.6395 93.9704 23.4975C93.297 31.7698 96.4053 39.1158 99.8961 46.338C101.141 48.9069 103.042 48.5461 103.791 45.9771C104.771 42.5465 105.235 38.9543 105.93 35.4321Z", "color": "#E1E1E1", "key": 16 },
    { "point": "M134.017 88.7985C133.823 92.2291 135.304 96.2684 138.326 99.6559C141.704 103.426 145.793 104.083 150.081 101.352C159.304 95.4928 160.753 78.6143 152.775 71.1121C148.152 66.7659 143.008 67.1267 139.059 72.2269C135.568 76.7347 134.103 81.9641 134.017 88.7985Z", "color": "#E1E1E1", "key": 15 },
    { "point": "M153.766 63.9435C153.577 63.1943 153.438 62.4332 153.351 61.6654C152.419 49.9409 147.301 39.2935 144.694 27.9998C144.252 26.0825 143.978 23.2012 141.246 23.5459C138.741 23.869 138.698 26.578 138.666 28.6622C138.472 40.6399 139.059 52.6445 137.513 64.5898C137.158 67.3203 137.615 69.4961 141.112 67.2826C142.451 66.3526 144.018 65.8015 145.645 65.6878C147.272 65.574 148.9 65.9017 150.356 66.6363C152.721 67.7673 154.035 67.4011 153.766 63.9435Z", "color": "#E1E1E1", "key": 15 },
    { "point": "M198.171 83.1057C198.171 74.9841 193.355 70.2771 184.902 70.1801C175.944 70.0724 167.815 77.2784 168.003 85.163C168.149 90.9525 178.707 102.569 184.008 102.763C190.343 102.984 198.16 92.1374 198.171 83.1057Z", "color": "#E1E1E1", "key": 14 },
    { "point": "M191.453 33.3911C191.259 29.395 191.701 23.8801 190.144 18.4837C188.975 14.4283 187.257 10.4752 182.565 10.5398C177.873 10.6045 178.535 15.3869 177.716 18.3813C173.364 34.2743 175.281 50.8567 172.615 66.9597C172.114 69.9757 173.897 70.0026 176.116 68.9686C180.615 66.8843 185.334 66.4804 189.966 68.15C193.667 69.4802 194.211 68.4085 193.646 64.9186C192.046 54.9821 191.41 44.9487 191.453 33.3911Z", "color": "#E1E1E1", "key": 14 },
    { "point": "M242.916 82.6532C243.137 77.763 242.184 72.959 239.285 68.7636C235.11 62.6833 227.17 61.7031 221.998 66.5232C210.384 77.3429 213.708 95.0671 228.403 100.846C232.977 102.639 236.619 101.503 239.231 97.4637C242.103 93.0259 243.105 88.0442 242.916 82.6532Z", "color": "#E1E1E1", "key": 13 },
    { "point": "M218.378 66.3402C223.533 61.9833 228.242 59.6782 234.243 61.687C237.206 62.678 237.211 60.1683 236.802 58.1864C234.792 48.4653 234.399 38.5611 233.273 28.7324C232.33 20.6216 231.792 12.3762 228.021 4.89554C226.943 2.74129 226.044 -0.258508 223.027 0.183114C220.01 0.624736 219.709 3.66762 219.595 6.10731C218.642 25.8187 220.269 45.5732 218.378 66.3402Z", "color": "#E1E1E1", "key": 13 },
    { "point": "M278.954 99.6073C283.764 99.4888 289.259 100.065 289.975 93.4569C291.096 83.095 289.437 72.9915 284.281 63.8359C282.056 59.8883 278.054 57.524 273.265 58.0895C268.637 58.628 266.052 61.9617 264.468 66.0763C262.005 72.463 260.707 79.2395 260.638 86.084C260.541 94.9056 262.927 97.4638 271.611 98.8695C274.035 99.2572 276.497 99.365 278.954 99.6073Z", "color": "#E1E1E1", "key": 12 },
    { "point": "M284.292 59.3499C284.551 58.1543 284.874 57.4434 284.831 56.7594C283.683 39.3692 278.743 22.8837 272.796 6.67298C272.484 5.81667 272.123 4.6049 271.018 4.73415C269.171 4.95496 269.51 6.67837 269.44 7.88475C268.487 23.3954 268.562 38.9545 266.919 54.4274C266.795 55.6284 266.472 57.8527 268.535 56.8617C274.31 54.0935 279.158 56.5493 284.292 59.3499Z", "color": "#E1E1E1", "key": 12 },
    { "point": "M316.975 101.788V101.67C320.779 101.67 324.587 101.713 328.39 101.67C331.671 101.622 334.402 100.022 334.235 96.7152C333.751 87.0803 333.804 77.1869 328.617 68.5322C325.11 62.6888 318.473 59.8128 312.553 61.0515C305.743 62.4787 301.999 67.0781 300.997 73.6216C300.003 80.3822 299.361 87.1898 299.074 94.017C298.837 98.9718 301.401 101.88 306.702 101.805C310.128 101.745 313.555 101.788 316.975 101.788Z", "color": "#E1E1E1", "key": 11 },
    { "point": "M326.984 62.5003C322.642 49.0955 321.597 36.7785 319.237 24.7093C318.446 20.4099 316.977 16.2634 314.885 12.4247C314.168 11.1375 313.419 9.4249 311.599 9.77497C309.778 10.125 309.568 11.9723 309.411 13.4264C307.795 28.5062 306.287 43.586 303.62 58.5311C303.303 60.3191 303.534 62.5326 306.249 60.9439C312.87 57.0608 319.275 57.8795 326.984 62.5003Z", "color": "#E1E1E1", "key": 11 },
    { "point": "M360.982 101.557V101.643C362.69 101.643 364.397 101.643 366.105 101.643C367.813 101.643 369.52 101.745 371.228 101.697C377.288 101.514 379.007 99.8388 379.206 93.8608C379.422 87.1933 378.506 80.5906 377.348 74.0739C375.92 66.0655 371.12 61.5631 364.419 61.0138C357.114 60.416 350.951 64.2936 348.037 71.6935C345.207 78.7659 343.835 86.3368 344.002 93.9523C344.131 100.205 345.446 101.428 351.883 101.535C354.911 101.6 357.944 101.557 360.982 101.557Z", "color": "#E1E1E1", "key": 21 },
    { "point": "M375.656 63.9436C371.971 46.5911 371.136 30.6981 369.73 14.8589C369.536 12.7047 369.429 9.71026 366.665 9.33865C363.498 8.91857 362.813 12.0853 362.059 14.2719C356.78 29.5456 356.166 45.794 351.285 62.4895C359.877 57.2115 367.182 57.0392 375.656 63.9436Z", "color": "#E1E1E1", "key": 21 },
    { "point": "M388.326 86.1809C387.788 98.1747 389.037 99.5588 400.242 99.4134C403.264 99.3757 406.281 98.8748 409.293 98.4978C414.184 97.8947 416.775 94.8356 417.265 90.177C418.307 81.0411 416.681 71.7991 412.584 63.5666C409.476 57.3516 403.243 56.2368 397.667 60.3676C394.274 62.8831 391.863 66.501 390.847 70.6003C389.425 75.9697 388.364 81.3715 388.326 86.1809Z", "color": "#E1E1E1", "key": 22 },
    { "point": "M393.466 60.2274C399.111 56.339 403.653 54.2278 409.056 56.6675C411.286 57.6747 411.679 56.3444 411.432 54.4379C409.487 39.5358 409.778 24.5422 409.573 9.56478C409.573 7.77675 410.144 5.11625 407.892 4.65847C405.641 4.20069 405.107 6.8989 404.477 8.54152C398.282 24.7684 395.874 41.787 393.466 60.2274Z", "color": "#E1E1E1", "key": 22 },
    { "point": "M434.001 81.8723C434.027 87.1072 435.951 94.0223 438.483 98.1854C440.32 101.212 442.625 102.968 446.466 102.068C456.863 99.6341 463.252 90.5809 461.873 80.0358C460.591 70.2447 453.981 63.1034 446.698 63.6527C439.113 64.2236 433.941 71.6396 434.001 81.8723Z", "color": "#E1E1E1", "key": 23 },
    { "point": "M457.817 33.531C457.817 30.2996 457.876 27.0682 457.817 23.8368C457.682 17.5626 457.547 11.2883 457.246 5.01943C457.149 2.92442 456.637 0.603209 454.17 0.0808028C451.557 -0.457761 450.463 1.6965 449.666 3.61917C448.546 6.23285 447.645 8.93494 446.973 11.6976C443.406 27.316 443.552 43.4406 440.206 59.0912C439.582 62.0371 441.354 62.2203 443.595 61.784C447.457 61.0085 451.271 61.2455 454.606 63.4374C458.199 65.8125 458.732 64.1699 458.56 60.8954C458.043 51.7775 457.429 42.665 457.817 33.531Z", "color": "#E1E1E1", "key": 23 },
    { "point": "M481.001 85.4862C481.275 92.019 485.31 98.5518 492.653 101.789C499.15 104.67 502.495 97.8732 506.805 94.6418C507.094 94.3966 507.356 94.1203 507.586 93.8178C511.895 88.793 511.976 82.1417 507.839 75.9159C506.076 73.3057 503.534 71.3188 500.574 70.2385C497.615 69.1582 494.39 69.0398 491.36 69.9002C484.524 71.8713 480.947 76.6215 481.001 85.4862Z", "color": "#E1E1E1", "key": 24 },
    { "point": "M484.567 69.9863C491.166 66.9811 496.677 65.8717 502.236 68.5861C506.131 70.4872 506.912 68.9846 506.433 65.3547C505.124 55.3805 504.741 45.3363 504.278 35.2975C503.922 28.4715 502.74 21.7137 500.76 15.1714C500.195 13.2648 499.942 10.5289 497.318 10.5559C494.695 10.5828 491.996 11.633 490.854 14.4497C489.978 16.5449 489.343 18.733 488.963 20.9717C486.598 36.8916 488.338 53.1401 484.567 69.9863Z", "color": "#E1E1E1", "key": 24 },
    { "point": "M543.531 87.7914C543.531 81.5279 542.588 76.7239 539.587 72.5824C535.278 66.6097 529.018 66.2273 524.31 71.8876C518.766 78.5443 517.43 86.1488 520.932 94.1949C523.027 99.0151 525.866 103.35 532.121 103.06C536.969 102.823 541.817 98.078 543.094 92.2022C543.358 90.746 543.504 89.271 543.531 87.7914Z", "color": "#E1E1E1", "key": 25 },
    { "point": "M539.178 41.4047C538.984 37.1608 538.898 32.4053 538.483 27.6767C538.343 26.1149 538.073 23.9068 535.913 23.7344C533.753 23.5621 533.376 25.754 532.913 27.3213C531.835 30.9619 531.232 34.7588 529.934 38.3133C526.987 46.3918 525.295 54.7611 524.008 63.2273C523.539 66.2271 523.367 68.5591 527.509 66.4102C528.77 65.7145 530.185 65.3456 531.624 65.3372C533.064 65.3287 534.483 65.6809 535.752 66.3617C539.846 68.4837 540.18 66.2594 540.061 62.9742C539.84 55.9459 538.391 48.9823 539.178 41.4047Z", "color": "#E1E1E1", "key": 25 },
    { "point": "M563.973 78.194C556.351 77.5746 554.46 79.1849 554.094 85.5184C553.841 88.3657 554.101 91.2352 554.864 93.9901C557.17 101.53 559.992 102.887 566.866 99.3434C571.09 97.1891 574.688 96.5967 578.971 99.7742C583.254 102.952 588.598 100.474 591.361 97.8946C595.531 94.017 593.877 87.9905 592.363 82.8149C591.194 78.808 588.355 77.2569 584.207 77.1169C577.445 76.9402 570.679 77.3004 563.973 78.194Z", "color": "#E1E1E1", "key": 26 },
    { "point": "M588.124 27.1814C585.969 28.797 585.969 31.0644 585.387 32.944C583.889 37.8664 582.246 42.7297 579.714 47.2159C578.524 49.3163 577.344 52.0199 574.327 51.449C571.752 50.9643 571.181 48.3738 570.616 46.1603C569.048 40.0261 569 33.7734 569.064 27.4776C569.064 25.7649 569.317 23.7884 567.384 23.0129C565.229 22.1296 563.817 23.7615 562.654 25.291C559.486 29.3813 557.682 34.3614 557.493 39.5306C557.127 48.8316 556.674 58.1272 556.415 67.4336C556.211 74.9304 557.25 76.1799 564.889 75.4097C572.663 74.6288 580.366 73.7132 588.14 75.092C590.623 75.5282 591.146 74.0579 591.108 72.1137C590.919 62.2257 593.139 52.4238 592.255 42.525C591.792 37.3225 591.232 32.0607 588.124 27.1814Z", "color": "#E1E1E1", "key": 26 },
    { "point": "M583.421 26.7828C583.028 24.1762 584.272 20.2285 580.269 19.4368C576.822 18.7528 575.874 22.3504 574.478 24.7255C570.767 31.0482 570.88 37.6941 572.97 44.4531C573.342 45.6648 573.476 47.3505 574.985 47.6468C576.601 47.9591 577.043 46.2788 577.711 45.2124C581.193 39.6739 583.161 33.3194 583.421 26.7828Z", "color": "#E1E1E1", "key": 26 },
    { "point": "M618.373 46.0364L620.08 46.4349C622.283 39.7298 624.589 33.057 626.636 26.3088C627.175 24.6069 627.654 22.1511 625.413 21.2786C623.172 20.4061 621.95 22.5442 621.061 24.1222C617.128 31.1343 618.459 38.6581 618.373 46.0364Z", "color": "#E1E1E1", "key": 27 },
    { "point": "M616.891 24.7039L615.442 24.4292C607.938 39.0997 604.361 54.9011 602.513 71.198C602.308 73.0399 602.771 75.0057 605.206 74.4025C612.716 72.5552 619.913 74.8926 627.234 75.6789C631.646 76.1528 634.043 74.5641 633.887 69.6524C633.634 61.8701 634.032 54.034 633.575 46.2895C633.219 40.2306 632.745 34.0264 629.389 27.4398C625.866 34.0102 625.079 40.333 622.677 46.0471C621.89 47.9267 621.168 50.5064 618.582 50.054C616.185 49.6286 615.932 47.0812 615.668 44.9969C615.498 43.6773 615.46 42.3441 615.555 41.0169C615.991 35.5882 616.449 30.1487 616.891 24.7039Z", "color": "#E1E1E1", "key": 27 },
    { "point": "M637.362 91.0386C637.33 82.7016 635.924 78.9263 632.26 78.7486C625.219 78.4093 618.534 75.7919 611.439 76.0558C606.764 76.2335 602.82 77.3268 601.032 82.2762C599.001 87.8234 600.014 95.2448 603.671 98.2553C606.71 100.765 610.718 102.602 614.181 100.593C618.863 97.8999 622.548 98.8532 626.916 100.916C631.517 103.07 635.029 101.201 636.559 96.2357C636.996 94.5338 637.265 92.7931 637.362 91.0386Z", "color": "#E1E1E1", "key": 27 },
    { "point": "M671.809 38.4156C670.683 36.019 672.062 34.581 672.348 32.9707C672.736 30.8865 674.88 28.1721 672.078 26.955C669.665 25.9048 668.184 28.7807 666.95 30.5795C657.824 43.8497 653.024 58.7033 651.01 74.564C650.703 76.9767 651.123 78.5709 653.946 79.0071C660.669 80.0519 667.262 81.5707 674.19 79.6426C678.112 78.5655 679.416 76.7721 679.001 72.8298C677.724 60.7457 677.724 48.5608 679.001 36.4768C679.276 33.8917 679.911 31.0535 678.241 28.3337C674.67 30.9942 675.057 35.5666 671.809 38.4156Z", "color": "#E1E1E1", "key": 28 },
    { "point": "M649 88.1467C649 88.529 649 88.906 649 89.2884C649.496 100.991 654.732 104.573 665.436 100.324C667.979 99.3165 670.23 99.9681 672.671 100.372C678.058 101.266 680.676 99.2949 681.215 93.9093C681.419 91.8681 680.999 89.757 680.794 87.6835C680.32 82.8364 677.848 81.1399 672.989 82.2979C667.375 83.6119 661.676 83.3265 655.976 82.3679C650.395 81.42 649.016 82.6048 649 88.1467Z", "color": "#E1E1E1", "key": 28 },
    { "point": "M43.2621 142.902C43.5854 131.706 39.6043 128.415 29.9831 130.779C27.4027 131.415 24.8654 132.25 22.3227 133.031C19.5957 133.922 16.6448 133.846 13.9674 132.815C7.6969 130.472 5.10573 131.603 2.26137 137.549C0.650443 141.012 -0.119269 144.806 0.0149787 148.622C0.155042 156.323 2.28831 158.052 9.9002 156.453C17.1694 154.966 24.6397 154.735 31.9871 155.769C40.0353 156.846 40.8541 157.034 42.4379 149.053C42.9012 146.635 43.0844 144.19 43.2621 142.902Z", "color": "#E1E1E1", "key": 48 },
    { "point": "M40.224 162.7C40.364 161.429 40.892 160.056 38.8611 159.63C28.5515 157.484 17.9202 157.387 7.57313 159.345C4.29781 159.943 4.20623 161.736 4.87961 164.262C6.07015 168.861 6.52804 173.579 7.21758 178.265C8.72057 188.438 7.47078 198.773 9.3724 208.893C9.9111 211.731 10.5737 214.666 13.7413 215.679C16.9089 216.691 19.1283 214.601 21.1323 212.517C22.1838 211.422 23.139 210.238 23.9874 208.979C33.3501 194.944 38.3224 179.363 40.224 162.7Z", "color": "#E1E1E1", "key": 48 },
    { "point": "M53.2691 170.865C53.2691 173.337 53.1398 175.819 53.2961 178.281C53.6893 184.533 54.0772 190.797 54.7613 197.028C54.9768 198.951 55.1923 201.848 57.6973 202.02C60.4824 202.204 60.31 199.263 60.7409 197.346C61.2312 195.132 61.4844 192.859 61.7645 190.597C62.5187 184.56 63.219 178.512 65.1906 172.717C65.794 170.94 66.4835 168.726 68.7461 168.796C71.3426 168.877 71.8706 171.355 72.28 173.353C72.9354 176.728 72.8471 180.205 72.0214 183.542C70.5885 189.644 69.0693 195.725 67.5717 201.81C67.2 203.318 66.7367 204.918 68.3744 205.909C70.012 206.9 71.2942 205.758 72.377 204.697C73.4485 203.619 74.4371 202.461 75.3344 201.234C83.6251 190.28 85.8984 177.424 86.755 164.202C87.2937 156.221 87.0566 156.216 79.2831 155.693C75.8678 155.467 72.4631 155.031 69.0478 154.988C50.5864 154.751 53.0429 152.839 53.2691 170.865Z", "color": "#E1E1E1", "key": 47 },
    { "point": "M88.0428 144.464C88.0913 135.379 84.9075 130.461 78.6209 131.145C73.2715 131.711 68.1 131.878 62.7991 130.865C58.5272 130.052 55.365 132.239 53.5388 136.003C51.4486 140.312 49.7194 144.82 52.2836 149.602C53.3018 151.498 55.1441 151.939 57.132 152.036C64.5338 152.408 71.9248 152.952 79.332 153.113C86.5021 153.307 87.9997 151.638 88.0428 144.464Z", "color": "#E1E1E1", "key": 47 },
    { "point": "M96.5629 177.344C96.8431 184.038 95.9865 191.885 97.8774 199.565C98.3137 201.342 98.5508 203.755 101.061 203.599C103.146 203.464 103.243 201.218 103.668 199.629C104.008 198.358 103.976 196.99 104.207 195.676C105.365 188.352 105.904 180.909 108.786 173.967C109.653 171.861 110.822 169.545 113.634 169.858C116.328 170.159 117.201 172.524 117.766 174.764C118.165 176.427 118.36 178.132 118.348 179.843C118.348 186.499 118.122 193.167 118.289 199.818C118.354 202.473 116.856 206.852 119.905 207.358C123.056 207.896 123.908 203.367 124.893 200.658C128.88 189.693 130.695 178.254 131.945 166.685C132.925 157.638 131.977 156.14 123.051 155.85C115.283 155.596 107.542 157.6 99.7251 156.119C97.0639 155.618 96.5845 157.589 96.5845 159.7C96.5575 165.226 96.5629 170.741 96.5629 177.344Z", "color": "#E1E1E1", "key": 46 },
    { "point": "M133.712 144.313C133.857 134.28 130.103 129.772 121.473 130.849C115.391 131.603 109.46 131.856 103.41 131.016C100.781 130.65 97.8182 130.596 96.1698 133.203C92.9375 138.298 93.821 143.759 95.5664 149.08C97.1071 153.787 101.169 154.325 105.306 154.191C111.356 153.991 117.422 153.113 123.444 153.383C132.527 153.77 133.588 153.14 133.712 144.313Z", "color": "#E1E1E1", "key": 46 },
    { "point": "M142.974 161.607C144.052 170.407 145.016 178.302 146.061 186.165C147.488 196.909 147.435 207.837 149.94 218.436C150.424 220.493 150.354 223.681 153.236 223.751C155.661 223.805 155.93 220.875 156.652 219.001C160.59 208.408 162.664 197.416 163.693 186.176C164.485 177.559 165.282 168.942 164.323 159.199C157.621 165.43 150.888 166.028 142.974 161.607Z", "color": "#E1E1E1", "key": 45 },
    { "point": "M167.846 144.109C167.911 136.763 161.576 130.774 153.738 130.769C145.318 130.763 136.989 137.727 137 144.728C137 152.984 144.994 162.258 152.024 162.188C159.863 162.129 167.765 153.087 167.846 144.109Z", "color": "#E1E1E1", "key": 45 },
    { "point": "M178.876 159.269C179.242 163.454 179.485 166.637 179.803 169.82C181.311 184.991 184.791 199.834 187.538 214.795C187.867 216.594 187.603 219.228 189.989 219.384C192.715 219.556 192.683 216.659 193.373 214.801C193.734 213.717 193.941 212.587 193.987 211.445C195.064 195.892 196.113 180.336 197.133 164.779C197.289 162.425 196.502 161.499 194.143 162.625C188.772 165.129 184.166 163.158 178.876 159.269Z", "color": "#E1E1E1", "key": 44 },
    { "point": "M201.836 141.804C202.084 134.404 197.715 130.031 189.817 129.772C181.737 129.514 174.082 135.54 174.001 142.229C173.893 150.981 181.893 161.079 188.988 161.187C196.083 161.294 201.448 153.146 201.836 141.804Z", "color": "#E1E1E1", "key": 44 },
    { "point": "M239.525 170.154C235.033 174.247 231.154 174.882 227.065 174.268C223.747 173.762 223.106 175.001 223.768 178.071C224.846 183.079 225.524 188.169 226.462 193.215C228.924 206.765 227.744 220.515 228.794 234.146C228.972 236.467 228.794 239.909 231.897 240.005C234.591 240.086 234.478 236.72 235.157 234.658C235.595 233.203 235.916 231.715 236.115 230.209C238.502 214.973 239.455 199.629 239.542 184.226C239.525 180.085 239.525 175.965 239.525 170.154Z", "color": "#E1E1E1", "key": 43 },
    { "point": "M241.961 149.72C241.993 144.55 241.815 140.199 240.21 136.025C236.595 126.621 228.256 124.715 220.876 131.609C217.821 134.468 216.324 138.034 216.076 142.234C215.629 149.774 217.191 156.986 219.847 163.971C221.463 168.193 223.726 172.351 228.902 172.227C234.392 172.092 237.899 168.522 239.8 163.518C241.594 158.833 242.036 153.91 241.961 149.72Z", "color": "#E1E1E1", "key": 43 },
    { "point": "M284.525 169.066C280.161 173.321 277.586 177.683 272.447 176.342C268.342 175.265 268.843 177.624 269.172 179.961C271.041 193.344 271.418 206.846 272.501 220.289C272.76 223.482 273.648 226.633 274.392 229.767C274.699 231.06 275.367 232.379 276.934 232.519C278.858 232.686 279.488 231.152 280.027 229.719C280.446 228.659 280.751 227.558 280.937 226.434C283.663 207.972 283.388 189.348 284.525 169.066Z", "color": "#E1E1E1", "key": 42 },
    { "point": "M281.093 128.835C277.769 129.191 274.376 129.519 270.982 129.912C263.02 130.849 260.429 133.876 261.102 142.03C261.715 149.229 263.315 156.309 265.859 163.072C267.475 167.31 267.884 173.493 273.988 173.304C279.914 173.143 281.724 167.38 283.938 162.77C284.157 162.251 284.318 161.709 284.417 161.154C286.297 152.597 286.642 143.861 287.299 135.179C287.59 131.372 285.699 128.571 281.093 128.835Z", "color": "#E1E1E1", "key": 42 },
    { "point": "M322.208 166.54C319.078 169.141 316.821 172.874 312.678 171.15C307.959 169.195 308.643 171.635 309.155 174.705C310.502 182.751 311.579 190.829 312.387 198.94C313.136 206.302 312.447 213.783 314.305 221.021C314.677 222.47 314.725 224.538 316.401 224.689C318.793 224.899 318.249 222.292 318.965 220.919C319.165 220.385 319.281 219.824 319.31 219.255C322.203 201.999 321.847 184.582 322.208 166.54Z", "color": "#E1E1E1", "key": 41 },
    { "point": "M304.027 142.784C303.854 150.076 304.442 157.255 307.997 163.788C309.344 166.276 310.981 168.904 314.3 168.926C317.618 168.947 319.24 166.26 320.468 163.707C324.481 155.359 326.108 146.473 326.308 137.231C326.421 132.023 324.471 129.864 319.1 129.417C304.743 128.243 303.951 128.733 304.027 142.784Z", "color": "#E1E1E1", "key": 41 },
    { "point": "M347.129 167.116C345.664 168.306 345.971 169.518 345.982 170.59C346.095 182.923 346.111 195.256 346.461 207.584C346.596 212.291 346.94 217.068 348.438 221.624C348.896 223.014 349.187 225.012 351.051 224.856C352.915 224.699 353.039 222.61 353.206 221.236C355.091 205.236 356.352 189.16 359.164 173.304C359.412 171.888 359.654 169.825 357.052 170.611C352.85 171.834 349.763 169.82 347.129 167.116Z", "color": "#E1E1E1", "key": 31 },
    { "point": "M364.034 144.27C364.158 129.654 361.47 127.182 348.729 128.803C345.061 129.271 342.443 130.995 342.195 134.727C341.489 145.294 342.48 155.634 348.869 164.59C352.64 169.895 356.589 169.615 359.907 164.052C363.754 157.589 364.109 150.383 364.034 144.27Z", "color": "#E1E1E1", "key": 31 },
    { "point": "M380.968 170.337C380.968 181.819 380.709 193.7 381.065 205.559C381.269 212.56 381.011 219.659 383.069 226.471C383.753 228.733 384.033 232.078 386.942 232.142C390.174 232.218 390.174 228.776 390.417 226.498C391.941 210.842 393.288 195.17 394.656 179.498C394.796 177.839 394.338 176.175 392.248 176.52C386.393 177.5 383.263 173.751 380.968 170.337Z", "color": "#E1E1E1", "key": 32 },
    { "point": "M377.186 144.259C377.536 151.439 377.935 160.777 383.214 169.006C384.776 171.441 386.479 173.854 389.824 173.735C393.293 173.595 394.586 170.784 395.691 168.193C398.923 160.653 400.706 152.64 402.074 144.566C403.825 134.28 400.631 130.327 390.298 129.761C377.315 129.05 377.127 129.228 377.186 144.259Z", "color": "#E1E1E1", "key": 32 },
    { "point": "M420.708 170.822C419.728 183.699 421.129 195.229 421.926 206.754C422.594 216.4 423.876 226.008 425.023 235.616C425.233 237.399 425.508 239.925 427.884 239.925C430.26 239.925 430.039 237.178 430.68 235.503C430.949 234.791 431.086 234.035 431.084 233.273C431.084 214.23 432.468 195.326 436.6 176.681C436.918 175.243 437.203 172.997 434.758 173.724C429.812 175.173 425.476 173.864 420.708 170.822Z", "color": "#E1E1E1", "key": 33 },
    { "point": "M418.052 152.085C417.81 156.318 418.365 161.004 420.611 165.35C422.664 169.314 425.761 172.254 430.351 172.173C434.785 172.098 437.559 168.942 439.154 165.059C441.504 159.442 443.036 153.516 443.7 147.464C444.374 140.894 443.038 134.986 437.607 130.553C431.52 125.582 425.821 126.525 421.985 133.348C418.855 138.971 417.853 145.127 418.052 152.085Z", "color": "#E1E1E1", "key": 33 },
    { "point": "M469.603 162.151C471.186 177.597 471.267 192.957 471.978 208.29C472.091 210.708 473.056 213.137 473.772 215.463C474.214 216.874 474.672 218.862 476.326 218.937C478.55 219.034 478.572 216.729 478.987 215.167C481.68 205.225 483.35 195.089 484.842 184.921C486.038 176.746 486.997 168.527 488.134 159.824C478.917 164.251 478.917 164.251 469.603 162.151Z", "color": "#E1E1E1", "key": 34 },
    { "point": "M476.972 129.724C468.31 129.789 463.505 135.589 465.417 143.899C466.279 147.788 467.47 151.597 468.978 155.284C470.885 159.797 474.516 161.925 479.536 161.041C486.669 159.781 493.311 149.947 492.578 141.815C491.98 135.099 484.956 129.649 476.972 129.724Z", "color": "#E1E1E1", "key": 34 },
    { "point": "M512.827 159.269C511.965 172.039 512.827 183.548 514.389 195.019C515.503 203.702 517.922 212.167 521.565 220.127C522.201 221.495 522.723 223.191 524.533 223.191C526.742 223.191 527.146 221.183 527.55 219.588C528.165 217.196 528.604 214.763 528.864 212.307C530.111 200.035 531.299 187.754 532.431 175.464C532.835 171.08 534.585 166.82 534.047 161.585C526.499 166.141 519.809 165.317 512.827 159.269Z", "color": "#E1E1E1", "key": 35 },
    { "point": "M511.001 144.098C510.936 154.826 516.388 162.248 524.274 162.156C532.355 162.064 541.071 153.501 541.125 145.595C541.179 137.953 533.281 130.941 524.425 130.774C515.725 130.607 511.054 135.265 511.001 144.098Z", "color": "#E1E1E1", "key": 35 },
    { "point": "M582.12 174.349C582.12 169.588 582.056 164.833 582.12 160.072C582.179 156.84 580.876 155.499 577.611 156.162C571.912 157.309 566.218 156.399 560.534 155.952C556.921 155.738 553.297 156.089 549.793 156.991C547.395 157.53 546.146 158.704 546.334 161.3C547.411 175.997 549.733 190.409 555.826 203.976C556.483 205.435 556.796 207.929 558.897 207.471C560.998 207.013 560.362 204.616 560.394 203.006C560.55 195.019 559.829 187 560.981 179.051C561.52 175.464 560.75 170.089 565.679 169.895C570.608 169.701 571.125 175.157 571.836 178.911C573.178 186.004 573.873 193.22 575.02 200.356C575.268 201.88 575.257 204.309 577.455 204.342C579.653 204.374 579.685 201.999 579.998 200.432C581.633 191.838 582.345 183.094 582.12 174.349Z", "color": "#E1E1E1", "key": 36 },
    { "point": "M566.702 153.286C569.17 153.286 571.642 153.318 574.11 153.286C581.323 153.167 583.084 151.67 584.113 144.518C584.385 142.639 584.477 140.738 584.388 138.842C584.097 132.858 581.156 129.928 575.176 130.882C569.206 131.892 563.107 131.881 557.14 130.849C552.384 130.009 547.88 131.275 545.591 136.122C542.978 141.653 543.845 147.265 546.302 152.597C547.223 154.595 549.534 154.552 551.398 154.099C556.44 152.855 561.585 153.189 566.702 153.286Z", "color": "#E1E1E1", "key": 36 },
    { "point": "M629.125 171.005C629.276 168.549 629.216 165.08 629.761 161.731C630.633 156.318 628.231 154.368 623.178 154.6C615.016 154.972 606.86 155.397 598.693 155.623C595.671 155.704 595.235 157.471 595.391 159.759C595.682 163.928 595.93 168.107 596.469 172.243C597.998 183.731 601.57 194.454 608.902 203.647C610.049 205.085 611.385 207.056 613.551 205.893C615.453 204.875 614.488 202.791 614.187 201.202C612.624 192.994 610.593 184.846 610.631 176.428C610.631 173.563 609.554 169.281 613.583 168.861C617.694 168.436 617.893 172.868 618.792 175.863C619.057 176.972 619.226 178.101 619.299 179.239C620.015 185.465 620.699 191.696 621.454 197.917C621.653 199.575 621.61 201.816 623.727 202.026C626.307 202.279 626.464 199.759 626.835 198.014C628.688 189.429 629.141 180.726 629.125 171.005Z", "color": "#E1E1E1", "key": 37 },
    { "point": "M606.063 153.248C608.757 152.974 611.989 152.564 615.183 152.349C618.588 152.112 622.003 152.058 625.419 151.929C626.981 151.869 628.619 151.632 629.454 150.227C632.422 145.207 630.677 140.263 628.624 135.562C626.879 131.566 623.334 130.068 619.191 131.135C614.589 132.317 609.764 132.333 605.153 131.183C602.459 130.553 599.766 130.93 597.848 133.219C593.867 137.991 593.156 143.581 594.885 149.29C596.614 154.999 601.684 152.667 606.063 153.248Z", "color": "#E1E1E1", "key": 37 },
    { "point": "M640.914 161.612C642.643 177.893 646.711 192.413 655.346 205.381C657.339 208.381 659.343 211.483 661.902 213.961C665.824 217.757 669.907 216.395 671.2 211.155C671.604 209.512 671.561 207.762 671.739 206.065C672.989 192.063 672.558 177.909 676.28 164.208C677.131 161.068 676.054 159.463 673.048 158.909C665.754 157.546 658.347 158.219 650.999 157.988C648.504 157.912 646.032 159.743 643.268 159.56C641.065 159.42 640.763 160.799 640.914 161.612Z", "color": "#E1E1E1", "key": 38 },
    { "point": "M638.005 141.771C637.935 145.769 638.611 149.745 639.998 153.496C640.989 156.345 642.907 158.144 646.102 156.894C654.074 153.765 662.311 155.505 670.343 155.817C679.701 156.205 681.241 155.311 680.767 145.891C680.615 142.268 679.557 138.741 677.691 135.632C676.339 133.375 674.346 131.522 671.663 132.185C664.364 133.994 657.468 132.249 650.352 130.935C641.56 129.325 638.032 132.81 638.005 141.771Z", "color": "#E1E1E1", "key": 38 }
    ]
  }
}
