import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Treatment } from '../models/treatment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TreatmentService {
    private itemsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    private itemsSubjectSecond: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    items: any[] = [];
    itemsSecond: any[] = [];

    items$ = this.itemsSubject.asObservable();
    itemsSecond$ = this.itemsSubjectSecond.asObservable();

    constructor(private http: HttpClient) { }

    getAllTreatments() {
        const url = environment.baseUrl + "/treatments/all";
        return this.http.get<Treatment[]>(url);
    }


    addItem(item: any, type) {
        if (type == "First Visit") {
            this.items.push(item);
            this.itemsSubject.next(this.items);
        } else {
            this.itemsSecond.push(item);
            this.itemsSubjectSecond.next(this.itemsSecond);
        }
    }
    changeItem(item: any, type) {
        if (type == "First Visit") {
            const key = item.key; // Replace 'key' with the actual key property of your item

            // Replace the color of the item with "#E1E1E1"
            this.items = this.items.map((i) => {
                if (i.key === key) {
                    return { ...i, color: "#E1E1E1" };
                }
                return i;
            });
            this.itemsSubject.next(this.items);
        }
        else {
            const key = item.key; // Replace 'key' with the actual key property of your item

            // Replace the color of the item with "#E1E1E1"
            this.itemsSecond = this.itemsSecond.map((i) => {
                if (i.key === key) {
                    return { ...i, color: "#E1E1E1" };
                }
                return i;
            });
            this.itemsSubjectSecond.next(this.itemsSecond);
        }
    }

    removeItem(item: any, type) {
        if (type == "First Visit") {
            const key = item.key; // Replace 'key' with the actual key property of your item
            this.items = this.items.filter((i) => i.key !== key);
            this.itemsSubject.next(this.items);
        }
        else {
            const key = item.key; // Replace 'key' with the actual key property of your item
            this.itemsSecond = this.itemsSecond.filter((i) => i.key !== key);
            this.itemsSubjectSecond.next(this.itemsSecond);
        }
    }
}