<div>
    <div>
        <form>
            <div>
                <div class="mb-3" class="text-center">
                    <label class="card-radio-label mb-2">
                        <input name="currency" id="buycurrencyoption1" class="card-radio-input">
                        <div class="card-radio">
                            <div>
                                <img src="assets/images/teeth/default.png" alt="" class="rounded-circle" height="25">
                                <span> {{title}}</span>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="row">
                    <div *ngFor="let item of treatments; let i = index;" class="col-xl-4 col-sm-4">
                        <div class="mb-3">
                            <label class="card-radio-label mb-2">
                                <input type="checkbox" name="t{{i}}" id="{{item.treatment_id}}" class="card-radio-input"
                                    [checked]="isChecked(item)" (change)="selectItem(item)">

                                <div class="card-radio">
                                    <div>
                                        <img src="assets/images/teeth/{{item.icon}}" alt="" class="rounded-circle"
                                            height="34">
                                        <span class="text-truncate"> {{item.name}}</span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>